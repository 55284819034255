<template>
  <div>
    <div class="text-center" v-if="loading">
      <b-spinner /> <br />
      carregando...
    </div>
    <div v-if="!loading">
      <h4><b-icon-calculator/> Resumo de saídas</h4>
      <table
        class="table table-sm table-stripped table-hover"
        style="font-size:14px"
      >
        <tbody>
          <tr v-for="saida in resumo" :key="`resumo_saida_${saida.empresa}`">
            <th>{{saida.empresa}}</th>
            <td class="text-right">{{saida.valor | currency}}</td>
          </tr>
        </tbody>
         <tfoot>
          <tr class="bgTotal">
            <th colspan="1">Total</th>
            <td class="text-right">{{total | currency}}</td>
          </tr>
        </tfoot>
      </table>

      <hr />
      <h4><b-icon-list-check/> Detalhado</h4>

      <table
        class="table table-sm table-stripped table-hover"
        style="font-size:13px"
      >
        <thead>
          <tr>
            <th>Empresa</th>
            <th>Caixa/Moto</th>
            <th>Data/Hora</th>
            <th>Usuário</th>
            <th>Motivo</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody style="font-size:12px">
          <tr v-for="saida in saidas" :key="`caixa_saida_${saida.cod_saida}`">
            <td>
              {{ saida.empresa }}
            </td>
            <td>
              {{ saida.caixa }}
              {{ saida.moto }}
            </td>
            <td>
              {{ saida.data | moment("DD/MM/YYYY HH:mm") }}
            </td>
            <td>
              {{ saida.usuario }}
            </td>
            <td>
              {{ saida.motivo }}
            </td>
            <td class="text-right">
              {{ saida.valor | currency }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="bgTotal">
            <th colspan="5">Total</th>
            <td class="text-right">{{total | currency}}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import CaixasLib from "../../libs/Caixas";
import moment from "moment";

export default {
  components: {},
  props: {
    data: Object,
    empresas: Array
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      saidas: [],
      resumo: [],
      loading: false,
      total:0,
    };
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.carregar();
      }
    },
    empresas: {
      deep: true,
      handler() {
        this.carregar();
      }
    }
  },
  computed: {},
  methods: {
    async carregar() {
      this.loading = true;
      this.saidas = await CaixasLib.getSaidasResumo({
        dt_i: moment(this.data.start).format("YYYY-MM-DD"),
        dt_f: moment(this.data.end).format("YYYY-MM-DD"),
        empresas:
          this.empresas && this.empresas.length > 0
            ? this.empresas.map(r => {
                return r.cod_empresa;
              })
            : []
      });
      this.resumo = await this.saidas.reduce((ret, vl) => {
        let has = ret.find(x => x.empresa === vl.empresa);
        if (has) {
          has.valor += vl.valor;
        } else {
          ret.push({
            empresa: vl.empresa,
            valor: vl.valor
          });
        }
        this.total += vl.valor
        return ret;
      }, []);
      this.loading = false;

      console.log("saidas", this.saidas);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app.scss";
</style>